import React from "react";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import ListaProducto from "../components/ListaCarrito/menudetalle";
import "react-perfect-scrollbar/dist/css/styles.css"
import Scrollbar from "react-perfect-scrollbar"
import Layout from "../components/layout"

const UseStyles = makeStyles((theme) => ({
    intro: {
        padding: "100px 0 0 !important",
        overflow: "visible !important",

        [theme.breakpoints.down("sm")]: {
            padding: "100px 0 0 !important",
        },
    },
}));
export default function carrito() {
    const classes = UseStyles();
    return (
        <Layout>
            <Scrollbar
                className="h-full-screen scrollable-content"
                option={{ suppressScrollX: true }}
            >
                <section className={clsx("section ", classes.intro)} id="schedule1">
                    <div className="container">
                        <ListaProducto />
                    </div>
                </section>
            </Scrollbar>
        </Layout>
    );
};



